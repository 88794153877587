<template>
  <BaseLayout>
    <Breadcrumbs />
    <section
      v-if="currentstore != null"
      class="our-shop--banner pb-25 pb-lg-35"
    >
      <b-container>
        <h1 class="font-weight-normal mb-30 mb-lg-35">
          {{ currentstore.name }}
        </h1>
        <div v-if="currentstore.banner_image != null" class="banner-img">
          <img :src="currentstore.banner_image.url" alt="Our Shop" />
        </div>
      </b-container>
    </section>
    <section v-if="currentstore != null" class="content pb-7">
      <b-container>
        <div v-html="currentstore.description"></div>
      </b-container>
    </section>
    <SubscribeSeparator />
    <section
      v-if="currentstore != null"
      class="address-and-opening-time pb-35 py-lg-10"
    >
      <b-container>
        <b-row>
          <b-col lg="4">
            <span class="h4 d-block">Openingstijden winkel</span>
            <ul class="opening-time list-unstyled pl-0 m-0 pb-30">
              <li
                v-for="openingTimeDatum of openingTimes"
                :key="openingTimeDatum.label"
              >
                <div
                  class="d-flex"
                  style="width: 100%"
                  v-for="(item, index) in openingTimeDatum.hours"
                  :key="`${openingTimeDatum.label}-${index}`"
                >
                  <span class="day d-block mr-25">
                    <span v-if="index == 0">{{
                      $t(openingTimeDatum.label)
                    }}</span>
                  </span>
                  <span v-if="item.from != null" class="time"
                    >{{ item.from }} - {{ item.to }}</span
                  >
                  <span v-if="item.from == null" class="time d-block">{{
                    $t("closed")
                  }}</span>
                </div>
              </li>
            </ul>
            <span class="h4 d-block">Adres:</span>
            <address v-if="currentstore != null">
              {{ currentstore.name }} <br />
              {{ currentstore.street }}, <br />
              {{ currentstore.postcode }} {{ currentstore.city }} <br />
              Telefoon {{ currentstore.phone }}
            </address>
          </b-col>
          <b-col lg="8" class="pt-5">
            <div
              v-if="currentstore.map_image != null"
              class="map-holder"
              :style="{ backgroundImage: `url(${currentstore.map_image.url})` }"
            ></div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/esf_thehague_wieleroutfits/core/components/BaseLayout";
import Breadcrumbs from "@/base/core/components/Breadcrumbs";
import SubscribeSeparator from "@/base/core//components/SubscribeSeparator";

export default {
  name: "OurShop",
  components: {
    BaseLayout,
    Breadcrumbs,
    SubscribeSeparator,
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.currentstore.name },
        { name: "keywords", content: this.currentstore.name },
        { name: "description", content: this.currentstore.name },
      ],
      title: this.currentstore.name,
    };
  },
  data() {
    return {
      largeScreen: false,
    };
  },
  mounted() {
    const bcrumb = {
      current: this.currentstore.name,
      routes: [{ name: "Winkels", route_link: "/winkels" }],
    };
    this.$store.commit("breadcrumbs/set", bcrumb);

    window.addEventListener("resize", this.setLargeScreen);
    window.addEventListener("load", this.setLargeScreen);
    this.setLargeScreen();
  },
  destroyed() {
    window.removeEventListener("resize", this.setLargeScreen);
    window.removeEventListener("load", this.setLargeScreen);
  },
  computed: {
    currentstore() {
      return this.$store.getters["stores/getStoreByCode"](
        this.$route.params.code
      );
    },
    openingTimes() {
      if (this.currentstore != null) {
        return this.currentstore.openingHours;
      }
      return null;
    },
  },
  watch: {
    currentstore() {
      if (this.currentstore != null) {
        const bcrumb = {
          current: this.currentstore.name,
          routes: [{ name: "Winkels", route_link: "/winkels" }],
        };
        this.$store.commit("breadcrumbs/set", bcrumb);
      }
    },
  },
  methods: {
    setLargeScreen() {
      this.largeScreen = window.innerWidth > 991;
    },
  },
};
</script>


