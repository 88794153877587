<template>
  <BaseLayout wrapperClass="faq">
    <Breadcrumbs />
    <section class="static pt-7">
      <b-container>
        <b-row>
          <b-col lg="3" class="d-none d-lg-block">
            <div class="sidebar">
              <span class="sidebar-heading">Links</span>
              <cmsBlockSimple identifier="side-menu-cms" />
            </div>
          </b-col>
          <b-col lg="9">
            <div>
              <h1>F.A.Q.</h1>
              <div
                class="pt-7"
                v-for="(section, index) in faq"
                :key="`sec` + index"
              >
                <h2>{{ section.title }}</h2>

                <div
                  v-for="(row, idx) in section.faq.items"
                  :key="`sec` + index + `row` + idx"
                >
                  <div
                    class="faq-header"
                    v-b-toggle="`sec` + index + `row` + idx"
                  >
                    {{ row.question }}
                  </div>
                  <b-collapse
                    class="answer"
                    :id="`sec` + index + `row` + idx"
                    v-html="row.long_answer"
                  >
                  </b-collapse>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/esf_thehague_wieleroutfits/core/components/BaseLayout";

import Breadcrumbs from "@/base/core/components/Breadcrumbs";
import cmsBlockSimple from "@/base/core/components/core/BlockSimple";
export default {
  name: "FAQ",
  components: {
    BaseLayout,
    Breadcrumbs,
    cmsBlockSimple,
  },
  computed: {
    faq() {
      return this.$store.getters["faq/getFaq"];
    },
  },
  mounted() {
    const bcrumb = { current: "F.A.Q.", routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: "F.A.Q." },
        { name: "keywords", content: "F.A.Q." },
        { name: "description", content: "F.A.Q." },
      ],
      title: "F.A.Q.",
    };
  },

  data: () => ({}),
};
</script>

