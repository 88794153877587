<template>
  <BaseLayout>
    <Breadcrumbs />
    <section class="brands-section">
      <b-container>
        <b-row>
          <b-col lg="3" class="pb-30 pb-lg-25">
            <BrandsSidebarLinks :sidebarData="brands" />
          </b-col>
          <b-col lg="9">
            <span class="d-none d-lg-block page-title">{{ $t("brands") }}</span>
            <b-row>
              <b-col
                cols="6"
                md="4"
                v-for="(brandData, index) in brands"
                :key="`bp` + index"
              >
                <b-link
                  v-if="brandData.category != null"
                  :to="`/` + brandData.category.url_path"
                  class="brand-card"
                >
                  <div class="brand-logo">
                    <img
                      :src="brandData.logo"
                      :alt="brandData.title"
                      v-if="brandData.logo"
                    />
                  </div>
                  <span class="brand-name">{{ brandData.title }}</span>
                </b-link>
                <div class="brand-card" v-if="brandData.category == null">
                  <div class="brand-logo">
                    <img
                      :src="brandData.logo"
                      :alt="brandData.title"
                      v-if="brandData.logo"
                    />
                  </div>
                  <span class="brand-name">{{ brandData.title }}</span>
                </div>
              </b-col>
            </b-row>
            <div
              class="bottom-txt d-lg-block"
              v-html="getCmsData.content"
            ></div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/esf_thehague_wieleroutfits/core/components/BaseLayout";
import Breadcrumbs from "@/base/core/components/Breadcrumbs";
import BrandsSidebarLinks from "@/base/core/components/BrandsSidebarLinks";

export default {
  name: "Brands",
  components: {
    BaseLayout,
    Breadcrumbs,
    BrandsSidebarLinks,
  },
  mounted() {
    const bcrumb = { current: this.$t("brands"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },

  computed: {
    brands() {
      return this.$store.getters["product/getBrandSlider"];
    },
    getCmsData() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`]("merken");
    },
  },
  data: () => ({}),
};
</script>

