<template>
  <BaseLayout>
    <Breadcrumbs />
    <section class="static pt-7">
      <b-container>
        <b-row>
          <b-col lg="12" v-if="cmsPageContent != null">
            <h1>{{ cmsPageContent.title }}</h1>
            <v-runtime-template
              v-if="cmsPageContent.content"
              :template="`<div class='cms-content'>${cmsPageContent.content}</div>`"
            ></v-runtime-template>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/esf_thehague_wieleroutfits/core/components/BaseLayout";
import Breadcrumbs from "@/base/core/components/Breadcrumbs";
import VRuntimeTemplate from "v-runtime-template";
export default {
  name: "Blog",
  components: {
    BaseLayout,
    Breadcrumbs,
    VRuntimeTemplate,
  },
  computed: {
    cmsPageContent() {
      return this.$store.getters["blog/getBlog"];
    },
    identifier() {
      return this.$route.params.code;
    },
  },
  watch: {
    identifier() {
      this.$store.dispatch("blog/loadBlog", {
        identifier: this.$route.params.code,
      });
    },
  },
  async mounted() {
    await this.$store.dispatch("blog/loadBlog", {
      identifier: this.$route.params.code,
    });
    const bcrumb = {
      current: this.cmsPageContent.title,
      routes: [
        {
          name: "Blog",
          route_link: "/blog",
        },
      ],
    };

    this.$store.commit("breadcrumbs/set", bcrumb);
  },

  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.cmsPageContent.meta_title },
        { name: "keywords", content: this.cmsPageContent.meta_keywords },
        { name: "description", content: this.cmsPageContent.meta_description },
      ],
      title: this.cmsPageContent.title,
    };
  },

  data: () => ({}),
};
</script>

