<template>
  <BaseLayout>
    <Breadcrumbs />
    <section class="static pt-7">
      <b-container>
        <h1>Blog</h1>
        <b-row style="width: 100%; justify-content: end">
          <b-pagination
            style="margin-left: auto; margin-right: 0"
            v-model="currentPageNr"
            :total-rows="totalBlogs"
            :per-page="blogsPerPage"
          ></b-pagination>
        </b-row>
        <b-row>
          <b-col
            v-for="(item, index) in currentPage"
            :key="index"
            lg="4"
            class="pb-30 pb-lg-25"
          >
            <div style="width: 100%; height: 200px">
              <img
                style="width: 100%; max-height: 200px"
                :src="item.featured_image"
              />
            </div>
            <h3 style="padding-top: 10px; padding-bottom: 10px">
              {{ item.title }}
            </h3>
            <div v-html="item.short_filtered_content"></div>
            <b-button
              @click="$router.push('/blog/' + item.identifier)"
              type="button"
              variant="info"
              class="text-white form-submit-btn"
              >{{ $t("read_more") }}</b-button
            >
          </b-col>
        </b-row>
      </b-container>
    </section>
  </BaseLayout>
</template>
<script>
import BaseLayout from "@/esf_thehague_wieleroutfits/core/components/BaseLayout";
import Breadcrumbs from "@/base/core/components/Breadcrumbs";
export default {
  name: "Blogs",
  components: {
    BaseLayout,
    Breadcrumbs,
  },
  mounted() {
    const bcrumb = {
      current: "Blog",
      routes: [],
    };

    this.$store.commit("breadcrumbs/set", bcrumb);
  },
  computed: {
    currentPageNr: {
      get() {
        return this.$store.getters["blog/getCurrentPageNr"];
      },
      set(val) {
        this.$store.dispatch("blog/goToPage", { pageNr: val });
      },
    },
    totalBlogs() {
      return this.$store.getters["blog/getTotalBlogs"];
    },
    blogsPerPage() {
      return this.$store.getters["blog/getBlogsPerPage"];
    },
    currentPage() {
      return this.$store.getters["blog/getCurrentPage"];
    },
  },
};
</script>
